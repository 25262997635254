<template>
  <v-card
    elevation="0"
    :class="['scrollbar', showCloseButton ? 'popup' : 'pa-1']"
    style="height: 100%; overflow-y: scroll"
  >
      <div style="width: 100%" class="d-flex align-center">
        <v-col class="pa-1">
          <v-card
            class="pa-3 d-flex align-center"
            elevation="0"
            style="height: 100%; border: 1px solid #B0BEC5"
          >
            <div class="fontBlack text-h6">
              Target: {{ scanUrl }}
            </div>
            <!-- <div
              class="fontBlack text-subtitle-2"
              style="margin: 0px 20px;"
            ></div> -->
          </v-card>
        </v-col>
        <v-col class="pa-1">
          <v-card
            elevation="0"
            style="height: 100%; border: 1px solid #B0BEC5"
            class="pa-3 d-flex align-center"
          >
            <div class="fontBlack text-h6">
              Time Taken:
            </div>
            <div
              :timeTaken="timeTaken"
              class="fontBlack text-h6"
              style="margin-left: 15px;"
            >
              {{ timeTaken }}
            </div>
          </v-card>
        </v-col>
      </div>
      <v-row style="margin-top: 10px;">
        <v-col cols="8">
          <horizontal-chart :vulnLevel1="vulnLevel1"></horizontal-chart>
        </v-col>
        <v-col
          cols="4"
          style="padding: 40px 50px; padding-left: 0px;"
        >
          <v-card
            elevation="1"
            outlined
            color="blue-grey lighten-5"
            style="padding: 20px;"
          >
            <div
              class="text-h5 font-weight-bold"
              style="margin-bottom: 5px"
            >
              TOTAL {{ findings }}
            </div>
            <div
              v-for="(vuln, idx) in vulnLevel1"
              :key="idx"
              style="display: flex; justify-content: start; align-items: center; width: 100%; position: relative; margin-bottom: 5px;"
            >
              <v-sheet
                :color="vuln.color"
                width="15"
                height="15"
              ></v-sheet>
              <div
                class="text-subtitle-1 font-weight-medium"
                style="margin-left: 10px;"
              >
                {{ vuln.level }}
              </div>
              <div
                class="text-h5 font-weight-bold"
                style="position: absolute; right: 0px;"
              >
                {{ vuln.value }}
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-card-text>
        <div class="fontBlack text-h6 font-weight-regular">
          Vulnerabilities Found
        </div>
        <div style="width: 100%">
          <v-card v-if="resultData.length == 0">
            <v-card-text>
              There's no Vulnerabilities
            </v-card-text>
          </v-card>
          <div v-if="resultData.length">
            <v-expansion-panels
              v-for="(data, idx) in resultData"
              :key="idx"
              multiple
            >
              <v-expansion-panel
                v-for="(item, i) in data"
                :key="i"
              >
                <v-expansion-panel-header
                  style="padding: 0px !important"
                >
                  <v-simple-table dense>
                    <tbody>
                      <tr>
                        <td
                          class="text-left"
                          style="min-width: 130px; width: 130px;"
                        >
                          <div
                            style="display: flex; justify-content: left; align-items: center;"
                          >
                            <div
                              :class="item.risk"
                              style="width: 12px; height: 12px; border-radius: 15px; margin-right: 5px;"
                            ></div>
                            {{ changeName(item.risk) }}
                          </div>
                        </td>
                        <td
                          class="text-left"
                          style="min-width: max-content; width: 300px"
                          v-html="item.alert"
                        ></td>
                        <td class="text-left tdWidth">
                          Instances
                        </td>
                        <td
                          class="text-left"
                          style="min-width: max-content; width: 50px"
                        >
                          {{ item.cnt }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <tbody>
                      <tr>
                        <td class="text-left">Description</td>
                        <td style="text-align: justify;">
                          {{ item.description }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">Solution</td>
                        <td style="text-align: justify;">
                          {{ item.solution }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">Other information</td>
                        <td class="text-left">
                          {{ item.other }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">References</td>
                        <td class="text-left">
                          {{ item.reference }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">CWE ID</td>
                        <td class="text-left" v-html="item.cweid"></td>
                      </tr>
                      <tr>
                        <td class="text-left">WASC ID</td>
                        <td class="text-left" v-html="item.wascid"></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <v-simple-table
                    dense
                    v-for="(inst, k) in item.instance"
                    :key="k"
                    style="border-top: thin solid rgba(0, 0, 0, 0.12); border-radius: 0px;"
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="3"
                          class="text-center"
                          style="border: transparent; width: 100px;"
                        >
                          instance
                        </td>
                        <td class="text-left" style="width: 200px;">
                          URL
                        </td>
                        <td class="text-left">{{ inst.url }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">METHOD</td>
                        <td class="text-left">{{ inst.method }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">Param</td>
                        <td class="text-left">{{ inst.param }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </v-card-text>
      <v-btn
        v-if="showCloseButton"
        icon
        @click="$emit('close')"
        style="position: absolute; top: 5px; right: 10px;"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
  </v-card>
</template>

<script>
import horizontalChart from "./horizontalChart.vue";

export default {
  components: {
    horizontalChart,
  },
  name: "scanResult",
  props: {
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    isHistoryPage: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Array,
      default: () => [],
    },
    timeTaken: {
      type: String,
      default: "0",
    },
    timelines: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    resultData: [],
  }),
  computed: {
    scanUrl() {
      const fullUrl = this.result[0]?.url || "N/A";
      try {
        const urlObj = new URL(fullUrl);
        return `${urlObj.protocol}//${urlObj.host}`;
      } catch (e) {
        console.warn("Invalid URL:", fullUrl);
        return "N/A";
      }
    },
    findings() {
      return this.result.length || 0;
    },
    vulnLevel1() {
      const levels = ["High", "Medium", "Low", "Informational", "Malware"];
      return levels.map((level) => {
        const count = this.result.filter((item) => item.risk === level).length;
        return {
          level,
          value: count,
          color: this.getColor(level),
        };
      });
    },
  },
  methods: {
    getColor(level) {
      switch (level) {
        case "High":
          return "#FF5252";
        case "Medium":
          return "#FFAB40";
        case "Low":
          return "#FFF176";
        case "Informational":
          return "#82B1FF";
        case "Malware":
          return "#BDBDBD";
        default:
          return "#FFFFFF";
      }
    },
    changeName(name) {
      return name === "Informational" ? "Information" : name;
    },
  },
  watch: {
    result: {
      handler(newVal) {
        if (!Array.isArray(newVal)) {
          console.error("newVal is not an array:", newVal);
          return;
        }

        this.resultData = [];
        const levels = ["High", "Medium", "Low", "Informational", "Malware"];
        const dataArr = levels.map((level) =>
          newVal.filter((item) => item.risk === level)
        );

        dataArr.forEach((group) => {
          if (group.length) {
            const uniqueAlerts = [];
            const aggregatedData = [];

            group.forEach((item) => {
              const idx = uniqueAlerts.indexOf(item.alert);
              if (idx === -1) {
                uniqueAlerts.push(item.alert);
                item.cnt = group.filter(
                  (i) => i.alert === item.alert
                ).length;
                item.instance = [
                  { url: item.url, method: item.method, param: item.param },
                ];
                aggregatedData.push(item);
              } else {
                aggregatedData[idx].instance.push({
                  url: item.url,
                  method: item.method,
                  param: item.param,
                });
              }
            });
            this.resultData.push(aggregatedData);
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.v-dialog__content,
.v-dialog::-webkit-scrollbar {
  scrollbar-width: none;
  background: transparent;
}

.scrollbar {
  scrollbar-width: none;
  background: transparent;
  width: 100vw !important;
  padding-left: 0px !important;
}

.tdBorder {
  border-radius: 10px;
}

.High {
  background-color: #ff5252;
}
.Medium {
  background-color: #ffab40;
}
.Low {
  background-color: #fff176;
}
.Informational {
  background-color: #82b1ff;
}
.Malware {
  background-color: #bdbdbd;
}
.popup {
  padding: 15px !important;
  padding-top: 45px !important;
}
</style>
