<template>
    <v-card class="pa-3" elevation="0">
        <canvas ref="barChart" style="width: 100%;"></canvas>
    </v-card>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
    data: () => ({
        chart: null,
        type: 'bar',
        data: {
            labels: ['High', 'Medium', 'Low', 'Info', 'Malware'],
            datasets: [{
                label: '',
                data: [],
                backgroundColor: ['#F44336', '#FFAB40', '#FFF176', '#82B1FF', '#BDBDBD']
            }],
        },
        options: {
            maintainAspectRatio: false,
            indexAxis: 'y',
            plugins: {
                legend: {
                    display: false
                },
                datalabels: {
                    align: 'end',
                    color: '#616161',
                    font: {
                        weight: 'normal',
                        size: 14
                    },
                    formatter: function(value, context) {
                        return "- " + context.chart.data.labels[context.dataIndex] + ": " + value;
                    }
                },
                tooltip: {
                    padding: 9,
                    backgroundColor: '#FFFFFF',
                    titleColor: '#000000',
                    titleFont: {
                        size: 13,
                        weight: 'bold'
                    },
                    titleAlign: 'center',
                    bodyColor: '#757575',
                    bodyFont: {
                        size: 12,
                        weight: 'bold'
                    },
                    borderColor: '#E0E0E0',
                    borderWidth: 1,
                    displayColors: false,
                    callbacks: {
                        label: function(context) {
                            return "Count: " + context.raw;
                        }
                    }
                }
            },
            scales: {
                y: {
                    grid: {
                        display: false
                    }
                },
                x: {
                    grid: {
                        color: (ctx) => (ctx.index === 0 ? '#757575' : ctx.index % 2 == 1 ? '#E0E0E0' : '#BDBDBD')
                    }
                },
            }
        },
        plugins: [ChartDataLabels]
    }),
    mounted() {
        this.createChart();
    },
    methods: {
        createChart() {
            this.chart = new Chart(this.$refs.barChart, {
                type: 'bar',
                data: this.data,
                options: this.options
            });
        },
        updateChart(newData) {
        if (this.chart && Array.isArray(newData) && newData.every(item => typeof item === 'number')) {
            this.chart.data.datasets[0].data = newData;
            this.chart.update();
        } else {
            console.warn("Chart data is invalid or chart is not initialized or data contains non-numeric values.");
        }
    }
    },
    props: {
        vulnLevel1: {
            type: Array,
            default: () => [],
        }
    },
    watch: {
        vulnLevel1: {
            handler(newVal) {
            if (Array.isArray(newVal) && newVal.length) {
                const newData = newVal.map(x => x.value);
                this.updateChart(newData);
            } else {
                console.warn('vulnLevel1 is empty');
                this.updateChart([0, 0, 0, 0, 0]);
            }
            },
            deep: true,
            immediate: true
        }
        }
}
</script>

<style>

</style>