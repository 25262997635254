<template>
    <v-container 
      fluid
      fill-height
      style="display: flex; justify-content: center; align-items: center;"
    >
        <v-card
            elevation="1"
            width="60%"
            min-width="400"
            outlined
            style="padding: 50px 30px;display: flex; justify-content: center; align-items: center; flex-direction: column;"
        >
            <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-bottom: 30px;">
                <v-card-title class="text-h4 font-weight-medium">SIGN UP</v-card-title>
            </div>
            <div>
                <div style="display: flex; align-items: baseline; justify-content: center; width: fit-content; position: relative;">
                    <v-text-field
                        v-model="email"
                        label="Email"
                        persistent-placeholder
                        clearable
                        style="width: 400px;"
                        prepend-icon="mdi-email"
                        :rules="emailRules"
                        :disabled="chkEmailDup"
                        @keypress.enter="emailvalidation"
                    ></v-text-field>
                    <div v-if="!chkEmailDup && !isCheckBtn" class="checkText" style="color: #ff5252;">
                        This email is duplicated
                    </div>
                    <v-btn outlined small v-if="!chkEmailDup" color="blue-grey darken-2" @click="emailvalidation" style="margin-left: 10px;">
                        check
                    </v-btn>
                    <v-btn icon small color="green darken-3" v-if="chkEmailDup" style="margin-left: 10px;position: absolute; right: -40px; bottom: 25px;">
                        <img id="checkBtn" src="@/assets/check.svg">
                    </v-btn>
                </div>
                <div v-if="chkEmailDup && !isCheckBtn" style="padding-left: 35px; display: flex; flex-direction: column; align-items: baseline; justify-content: center; width: fit-content; position: relative;">
                    <div class="text-caption" style="line-height: 1.2em;">An email has been sent for email authentication. <br> Please check the email for authentication.</div>
                    <div style="color: black; margin: 10px 5px; margin-bottom: 0px; font-size: 11px;">
                        Email Verification
                    </div>
                    <div class="d-flex flex-row justify-left">
                        <v-text-field
                            style="width: 235px;"
                            filled
                            :disabled="isSubmit"
                            v-model="vNum"
                            @keypress.enter="numValid"
                            placeholder="Enter Verification Code"
                        ></v-text-field>
                        <div ref="verMess" class="checkText" style="color: #ff5252; margin-bottom: 10px; margin-left:12px;"></div>
                        <v-btn outlined small v-if="!isSubmit && !isTimeOut" @click="numValid" color="blue-grey darken-2" style="margin: 2px 10px;">
                            check
                        </v-btn>
                        <v-btn
                            outlined
                            small
                            :disabled="isResendDisabled"
                            v-if="isTimeOut"
                            color="blue-grey darken-2"
                            @click="emailvalidation"
                            style="margin-left: 10px;"
                            >
                            Resend
                        </v-btn>
                        <div :v-model="timer" v-if="!isSubmit && !isTimeOut" style="margin-top: 4px; color:red;">
                            {{ timer }}
                        </div>
                        <div ref="verTime" class="checkText" style="color: #ff5252; margin-bottom: 10px; margin-left:12px;"></div>
                        <!-- <div v-if="isTimeOut" style="color:red; font-size: 11px; position:relative; top: 38px; right: 130px">
                            Time has expired, please send again.
                        </div> -->
                        <v-btn icon small color="green darken-3" v-if="isSubmit" style="margin-left: 10px; position: absolute; right: -7px; bottom: 30px;">
                            <img id="checkBtn" src="@/assets/check.svg">
                        </v-btn>
                    </div>
                </div>
                <div class="inputWrap">
                    <v-text-field
                        v-model="username"
                        :rules="[() => !!username || 'Username is required',]"
                        label="Username"
                        persistent-placeholder
                        clearable
                        prepend-icon="mdi-account"
                    ></v-text-field>
                </div>
                <div class="inputWrap">
                    <v-text-field
                        v-model="password"
                        label="Password"
                        :rules=pwdRules
                        persistent-placeholder
                        clearable
                        prepend-icon="mdi-lock"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show1 = !show1"
                        :type="show1 ? 'text' : 'password'"
                        @input="pwdValidation"
                    ></v-text-field>
                </div>
                <div style="padding-left: 30px; display: flex; align-items: baseline; justify-content: center; width: fit-content; position: relative;">
                    <v-text-field
                        v-model="chkPassword"
                        label="Check Password"
                        persistent-placeholder
                        clearable
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show2 = !show2"
                        style="width: 370px;"
                        :type="show2 ? 'text' : 'password'"
                        :rules="[pwdValidation]"
                    ></v-text-field>
                    <v-btn icon small color="green darken-3" v-if="chkPwd" style="margin-left: 10px;position: absolute; right: -40px; bottom: 25px;">
                        <img id="checkBtn" src="@/assets/check.svg">
                    </v-btn>
                </div>
                <div style="display: flex; justify-content: end; width: 500px; gap: 5px; margin-top: 20px;">
                    <v-btn small elevation="" outlined color="error" :to="'/'">
                        cancel
                    </v-btn>
                    <v-btn small elevation="" :disabled="!isActive" outlined color="green darken-3" @click="signup">
                        sign up
                    </v-btn>
                </div>
            </div>
        </v-card>
    </v-container>
</template>

<script>
// import { address } from '@/mixins/api';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name:'SignUp',
    data:()=>({
        isSend:false,
        isSubmit:false,
        vNum:null,
        email:null,
        show1: false,
        show2: false,
        username:null,
        password:null,
        chkPassword:null,
        chkEmailDup:false,
        chkPwd:false,
        isCheckBtn:true,
        isActive:false,
        timeInterval:null,
        isTimeOut:false,
        isResendDisabled: false,
        timer:null,
        min:0,
        sec:0,
        emailRules: [
            v => !!v || 'Email is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
        ],
        pwdRules:[
            (v) => !!v || "Password is required",
            (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
        ]
    }),
    computed:{
        isEValid(){
            return this.emailRules.every(rule=>rule(this.email)===true);
        }
    },
    watch:{
        chkEmailDup:{
            handler(){
                this.checkAllFilled();
            },
            immediate:true
        },
        username:{
            handler(){
                this.checkAllFilled();
            },
            immediate:true
        },
        chkPwd:{
            handler(){
                this.checkAllFilled();
            },
            immediate:true
        },
        isSubmit:{
            handler(){
                this.checkAllFilled();
            },
            immediate:true
        }
        
    },
    methods:{
        sendVer:function(){
            axios.post(`${this.$apiUrl}/user/sendverifymail`,{
                email: this.email
            }).then((res)=>{
                if(res.data.result){
                    this.chkEmailDup=true;
                    this.isCheckBtn=false;
                    this.resetTimer();
                }else{
                    alert("이메일 요청 실패");
                }
            })
        },
        numValid:function(){
            console.log(this.vNum);
            axios.post(`${this.$apiUrl}/user/verifycode`,{
                email: this.email,
                verifycode: this.vNum
            }).then((res)=>{
                if(res.data.result){
                    this.isSubmit=true;
                    this.$refs.verMess.innerHTML=null;
                    // alert("이메일 인증 완료");
                }else{
                    // alert("이메일 인증 실패");
                    this.$refs.verMess.innerHTML='Check your verification email and Try again';
                }
            })
        },
        checkAllFilled:function(){
            if(this.isSubmit && this.username.length!==0 && this.chkPwd){
                this.isActive=true;
            }else{
                this.isActive=false;
            }
        },
        emailvalidation:function(){
            if(this.isEValid){
                this.startTimer();
                axios.post(`${this.$apiUrl}/user/chkemaildup`,{
                    email:this.email
                }).then((res)=>{
                    // console.log(res);
                    if(!res.data.dup_email){ // 이메일이 중복되지 않은 경우
                       this.sendVer();
                    }else{ // 이메일이 중복된 경우
                        this.chkEmailDup=false;
                        this.isCheckBtn=false;
                    }
                })
            }else{
                console.log("email check");
            }
        },
        pwdValidation:function(){
            if(this.chkPassword!=null){
                if(this.password !=this.chkPassword || this.password.length < 8){
                    this.chkPwd=false;
                    return 'Password do not match';
                }else{
                    this.chkPwd=true;
                    return true;
                }
            }else{
                this.chkPwd=false;
                return 'Check Password is required';
            }
            
        },
        signup:function(){
            if(this.isSubmit && this.username.length !==0 && this.chkPwd){
                console.log("signup")
                axios.post(`${this.$apiUrl}/user/create`,{
                    id: this.username,
                    pw: this.password,
                    email: this.email
                }).then((res)=>{
                    console.log(res);
                    console.log("signup finish")
                    Swal.fire({
                            title: 'You have successfully completed your membership.',
                            text: "Please log in again",
                            icon: 'success',
                            width: '400px',
                            confirmButtonText:'OK',
                            confirmButtonColor: '#4CAF50',
                            customClass:{
                                confirmButton: 'custom-confirm-button'
                            }
                        }).then((result)=>{
                            if(result.isConfirmed){
                                this.$router.push('/login');
                            }
                        });
                })
            }else{
                console.log("information error");
            }
        },
        startTimer() {
            this.isResendDisabled = true;
            this.min = 2;
            this.sec = 60;
            this.isTimeOut = false;
            clearInterval(this.timeInterval); 
            this.timeInterval = setInterval(() => {
                if (this.sec === 0) {
                    this.sec = 59;
                    this.min--;
                } else {
                    this.sec--
                }
                this.timer = `${this.min.toString().padStart(2, "0")}:${this.sec.toString().padStart(2, "0")}`;
                if (this.sec === 0 && this.min === 0 && this.isSubmit === false) { 
                    this.isTimeOut = true;
                    this.isResendDisabled = false;
                    if(this.$refs.verMess){
                        this.$refs.verMess.innerHTML=null;
                    }
                    if(this.$refs.verTime){
                        this.$refs.verTime.innerHTML = "Time has expired, please send the code again.";
                    }
                    clearInterval(this.timeInterval);
                }
                else{
                    if(this.$refs.verTime){
                       this.$refs.verTime.innerHTML = null; 
                    }
                }
            }, 1000);
        },
        resetTimer: function() {
            clearInterval(this.timeInterval);
            this.isTimeOut = false; 
            this.startTimer(); 
        }
    }
}
</script>

<style scoped>
.inputWrap{
    width: 400px; 
    display: flex; 
    align-items: baseline;
    justify-content: left;
}
#checkBtn{
    filter: invert(39%) sepia(11%) saturate(2527%) hue-rotate(73deg) brightness(99%) contrast(91%);
}
#failedBtn{
    filter: invert(30%) sepia(81%) saturate(2254%) hue-rotate(344deg) brightness(102%) contrast(91%);
}
.checkText{
    margin-left: 5px;
    font-size: 11px;
    position: absolute;
    bottom: 0px;
    left: 28px;
}



</style>