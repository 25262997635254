<template>
    <v-container fluid style="height: 100vh;">
        <v-row style="min-height: 20vh;">
            <v-col cols="12" style="padding-bottom: 0px !important;">
                <v-card outlined class="d-flex flex-row align-top" style="gap: 20px; padding: 20px !important">
                    <div style="height: 100%;">
                        <v-card-title class="pa-0" style="width:fit-content;text-align: center;">
                            WiKi Web <br> Scanner
                        </v-card-title>
                    </div>
                    <div style="min-width: 400px; width: 400px;">
                        <div :v-model="timeTaken">Enter URL to scan: {{ timeTaken }} </div>
                            <v-text-field
                            style="width: 400px !important;"
                            placeholder="URL to scan "
                            outlined
                            clearable
                            hide-details
                            v-model="currentScanUrl"
                            @input="resetStopWatch"
                            @keyup.enter="spiderStart()"
                            >
                        </v-text-field>
                        
                        <v-btn class="text-caption" text style="height: fit-content; padding: 0px 5px; margin: 5px 0px;" @click="loginInput = !loginInput">
                            > Log in ...
                        </v-btn>

                        <v-divider v-if="loginInput"></v-divider>
                        
                        <div v-if="loginInput" style="margin-top: 5px;">
                            <v-text-field
                                style="width: 100% !important; margin-bottom: 10px;"
                                placeholder="Login URL to scan "
                                outlined
                                clearable
                                hide-details
                                v-model="loginUrl"
                            ></v-text-field>
   
                            <div class="d-flex flex-row " style="width: 100%; gap:5px;">
                                <v-text-field
                                style="width: 200px !important;"
                                placeholder="ID"
                                outlined
                                clearable
                                hide-details
                                v-model="loginId"
                                ></v-text-field>
                                <v-text-field
                                style="width: 200px !important;"
                                placeholder="PW "
                                outlined
                                clearable
                                hide-details
                                type="password"
                                v-model="loginPw"
                                ></v-text-field>
                            </div>
                        </div>
                    </div>
                    <div style="margin-left: 10px; width: max-content; height: 100%; display: flex; justify-content: center; align-items: center; gap: 5px; margin-top: 20px;">
                        <v-btn small outlined elevation="1" style="height: 40px;" color="success" :disabled="scanDisabled" @click="spiderStart()">SCAN</v-btn>
                        <v-btn small outlined elevation="1" style="height: 40px;" color="error" @click="stopStopWatch()">STOP</v-btn>
                    </div>
                    <div v-if="errorMessage" style="margin-left: 20px; color: red; white-space: nowrap; margin-top: 30px">
                        {{ errorMessage }}
                    </div>
                </v-card>
                <v-divider style="margin-top: 10px;"></v-divider>
            </v-col>
        </v-row>
        <v-row :class="[loginInput?'loginInput':'URLInput']" >
            <v-col cols="9" style="height: 100%;">
                <scan-result-vue 
                    v-if="scanResult || selectedHistoryItem" 
                    :result="resultObj" 
                    :timeTaken="timeTaken"
                    :timelines="timelines"
                    :scanUrl="scanUrl"
                >
                </scan-result-vue>
                <v-card v-if="!scanResult && curStatus!==100 && curStatus!=null" style="height: 100% !important;">
                    <v-container style="height: 100%">
                        <v-row
                            class="fill-height"
                            align-content="center"
                            justify="center"
                        >
                            <v-col
                                class="text-h5 font-weight-medium text-center"
                                cols="12"
                                style="padding-bottom: 0px !important;"
                            >
                                Scanning for vulnerabilities.
                            </v-col>
                            <v-col
                                class="text-subtitle-1 text-center"
                                cols="12"
                                style="padding-top: 0px !important;"
                            >
                                It takes time to scan, so please wait for scan result.
                            </v-col>
                            <v-col cols="6">
                                <v-progress-linear
                                    color="orange"
                                    indeterminate
                                    rounded
                                    height="10"
                                ></v-progress-linear>
                            </v-col>
                            <v-col
                                class="text-h5 font-weight-medium text-center"
                                cols="12"
                                style="padding-bottom: 0px !important;"
                            >
                                {{curStatus}}%
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="3" style="height: 100% !important; padding-left: 0px !important;">
                <v-card style="height: 100%;">
                    <v-card-title>
                        HISTORY
                    </v-card-title>
                    <v-divider></v-divider>
                        <v-list v-if="timelines.length" style="height: 85%; overflow: auto">
                            <v-list-item-group v-model="selectedTimelineId" active-class="v-item--active">
                            <v-list-item 
                                v-for="(timeline, index) in timelines" 
                                :key="index" 
                                @click="showScanResult(timeline.id)"
                                :value="timeline.id"
                            >
                                <v-list-item-content>
                                    <v-list-item-subtitle v-if="timeline.isCurrent">Current Scan</v-list-item-subtitle>
                                    <v-list-item-title>{{ formatTimeline(timeline.start_time) }}</v-list-item-title>
                                </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    <v-card-text v-else>There's no scan history</v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import scanResultVue from './scanResult.vue'
// import { address } from '@/mixins/api';

export default {
    name: 'ScanPage',
    components: {
        scanResultVue
    },
    data: () => ({
        loginId:null,
        loginPw:null,
        loginUrl:null,
        loginInput:false,
        currentScanUrl: null,
        scanCompleted: false,
        value: 0,
        isProcessingResults: false,
        scanResult: false,
        firstPage: true,
        scanUrl: null,
        scanDisabled: false,
        timeInterval: null,
        statusInterval: null,
        scanInterval: null,
        selectedTimelineId: null,
        errorMessage: '',
        sec: 0,
        min: 0,
        timeTaken: null,
        spiderId: null,
        findings: 0,
        resultObj: [],
        vulnLevel: [
            {
                level: 'High',
                color: 'error',
                value: 0
            },
            {
                level: 'Medium',
                color: 'orange accent-2',
                value: 0
            },
            {
                level: 'Low',
                color: 'yellow lighten-2',
                value: 0
            },
            {
                level: 'Info',
                color: 'blue accent-1',
                value: 0
            },
            {
                level: 'Malware',
                color: 'grey lighten-1',
                value: 0
            },
        ],
        resultLevel: [],
        timelines: [],
        selectedHistoryItem: null,
        scanId: null,
    }),
    methods:{
            spiderStart: async function() {
                console.log('spiderStart');
                clearInterval(this.scanInterval);
                clearInterval(this.statusInterval);
                this.selectedHistoryItem = null;
                if (!this.isValidUrl(this.currentScanUrl)) {
                    this.showError("Please enter a valid URL starting with 'http' or 'https'");
                    alert("Please enter a valid URL starting with 'http' or 'https'");
                    return;
                }
            try {
                this.resetStopWatch();
                this.startStopWatch();
                this.firstPage = false;
                this.scanResult = false;
                this.scanDisabled = true;
                this.resultObj = [];
                this.findings = 0;
                this.vulnLevel.forEach(y => y.value = 0);
                this.selectedHistoryItem = null;
                this.scanUrl = this.currentScanUrl;
                const tempId = Date.now().toString();
                localStorage.setItem('scanStartTime', new Date().toISOString());
                localStorage.setItem('isCurrentScan', 'true');
                localStorage.setItem('currentScanTimelineId', tempId);
                this.timelines.unshift({
                    id: tempId,
                    start_time: new Date(),
                    scan_url: this.scanUrl,
                    isCurrent: true
                });
                localStorage.setItem('scanTimeline', JSON.stringify(this.timelines));
                localStorage.setItem('scanRunning', 'true');
                console.log('Sending request:', { url: this.scanUrl, login_url: this.loginUrl, auth_id: this.loginId, auth_pw: this.loginPw });
                const spiderResponse = await axios.post(`${this.$apiUrl}/webscan/spider`, {
                    url: this.scanUrl,
                    login_url: this.loginUrl,
                    auth_id: this.loginId,
                    auth_pw: this.loginPw
                }, { withCredentials: true });

                console.log('Response:', spiderResponse);
                if (spiderResponse.data.error) {
                    if (spiderResponse.data.error === "site own validation fail") {
                        this.stopStopWatch();
                        this.showError("Site ownership validation failed. Please check your meta tag.");
                    } else if (spiderResponse.data.error === "session cannot check") {
                        this.stopStopWatch();
                        this.showError("Session validation failed. Please check your session.");
                    } else if (spiderResponse.data.error === "login validation failed") {
                        this.stopStopWatch();
                        this.showError("Login validation failed. Please check your login credentials.")
                    }
                    return;
                }
                this.spiderId = spiderResponse.data.scan;
                this.statusInterval = setInterval(async () => {
                    this.scanDisabled = true;
                    const status = await this.SpiderStatus(this.spiderId);
                    if (status) {
                        clearInterval(this.statusInterval);
                        this.scanStart(tempId);
                    }
                }, 1000);

            } catch (error) {
                this.showError("An error occurred during scanning.");
                this.stopStopWatch()
                console.error(error);
            }
        },

        showError(message) {
            console.log('Error:', message);
            this.errorMessage = message;
            setTimeout(() => {
            this.errorMessage = '';
        }, 5000);
        },
        
        clearIntervals() {
            clearInterval(this.timeInterval);
            clearInterval(this.statusInterval);
            clearInterval(this.scanInterval);
        },
        
        SpiderStatus: async function (spiderId) {
            var rstatus = false;
            await axios.post(`${this.$apiUrl}/webscan/spider/status`, {
                spider_id: spiderId
            },{
                withCredentials: true
            }).then((res) => {
                this.value = Math.floor(res.data.status / 2);
                this.$store.commit('setScanStatus', this.value);
                if (res.data.status == "100") {
                    rstatus = true;
                }
            }).catch((error) => {
                console.error("Spider status error:", error);
            });
            return rstatus;
        },
        async scanStart(tempId) { 
            this.scanDisabled = true; 
            this.timeTaken = null;
            const scanId = await axios.post(`${this.$apiUrl}/webscan/scan`, { spider_id: this.spiderId }, { withCredentials: true }).then((res) => res.data.scan);
            this.scanId = scanId;
            localStorage.setItem('scanId', this.scanId);
            localStorage.setItem('scanRunning', 'true');
            const timelineIndex = this.timelines.findIndex(tl => tl.id === tempId);
            if (timelineIndex !== -1) {
                this.timelines[timelineIndex].id = scanId;
                this.timelines[timelineIndex].isCurrent = true;
            }

            this.scanInterval = setInterval(async () => {
                if (await this.scanStatus(scanId) === '100') {
                    clearInterval(this.scanInterval);
                    this.scanCompleted = true;
                    await this.scanAlert(scanId);
                    await this.updateHistory();
                    this.scanDisabled = false;
                    this.updateTimeTaken(scanId)
                    this.scanResult = true;
                    if (this.timelines.length > 0) {                             
                        this.autoSelectLatestScan();
                    }
                    this.autoSelectLatestScan();
                    localStorage.removeItem('scanId');
                    localStorage.removeItem('scanUrl');
                    localStorage.removeItem('scanStartTime');
                    localStorage.removeItem('scanRunning');
                }
            }, 3000);
        },

        startStopWatch: function () {
            this.timeInterval = setInterval(() => {
            if (this.sec === 59) {
                this.sec = 0;
                this.min++;
            } else {
                this.sec++;
            }
            this.timeTaken = `${this.min.toString().padStart(2, "0")}:${this.sec.toString().padStart(2, "0")}`;
            localStorage.setItem('timeTaken', this.timeTaken);
        }, 1000);
        },

        stopStopWatch: async function () {
            this.clearIntervals();
            this.scanDisabled = false;
            this.firstPage = true;
            this.scanCompleted = false;
            this.curStatus = 0;
            this.$store.commit('resetScanStatus');
            localStorage.removeItem('scanRunning');
            await this.scanStop();
        },
        resetStopWatch: function () {
            this.clearIntervals();
            this.min = 0;
            this.sec = 0;
        },
        scanStatus: async function (scanId) {
            var response = null;
            await axios.post(`${this.$apiUrl}/webscan/scan/status`, { scan_id: scanId }, {withCredentials: true})
                .then((res) => {
                    this.value = 50 + Math.floor(res.data.status / 2);
                    this.$store.commit('setScanStatus', this.value);
                    response = res.data.status;
                })
                .catch((error) => {
                    console.error("Scan status error:", error);
                });
            console.log('status')
            return response;
        },
        async scanAlert(scanId) {
            this.resultObj = [];
            this.findings = 0;
            this.vulnLevel.forEach(y => y.value = 0);
            try {
                const response = await axios.post(`${this.$apiUrl}/webscan/scan/alerts`, { scan_id: scanId }, { withCredentials: true });
                if (Array.isArray(response.data)) {
                    this.resultObj = response.data;
                    this.$store.commit('setCurResult', this.resultObj);
                    this.$store.commit('setScanStatus', undefined);
                    let highCnt = 0, medCnt = 0, lowCnt = 0, infoCnt = 0, malCnt = 0;
                    this.resultObj.forEach((x) => {
                        switch (x.risk) {
                            case "High": highCnt++; break;
                            case "Medium": medCnt++; break;
                            case "Low": lowCnt++; break;
                            case "Informational": infoCnt++; break;
                            case "Malware": malCnt++; break;
                        }
                    });

                    this.findings = highCnt + medCnt + lowCnt + infoCnt + malCnt;
                    this.vulnLevel.forEach((y, index) => {
                        let newValue = 0;
                        switch (y.level) {
                            case "High": newValue = highCnt; break;
                            case "Medium": newValue = medCnt; break;
                            case "Low": newValue = lowCnt; break;
                            case "Info": newValue = infoCnt; break;
                            case "Malware": newValue = malCnt; break;
                        }
                        this.$set(this.vulnLevel, index, { ...y, value: newValue });
                    });
                    this.resultLevel = this.vulnLevel;
                    if (!this.selectedHistoryItem) {
                        this.scanResult = true;
                    }
                } else {
                    this.findings = 0;
                    this.scanResult = false;
                }
                } catch (error) {
                    console.error("Scan alert error:", error);
                    this.resultObj = [];
                    this.findings = 0;
                    this.scanResult = false;
                } finally {
                    if (!this.selectedHistoryItem) {
                    this.stopStopWatch();
                }
            }
        },

        updateHistory: async function () {
            try {
                const response = await axios.post(`${this.$apiUrl}/webscan/history`, {}, { withCredentials: true });
                const uniqueHistory = new Map();
                response.data.forEach(item => {
                    if (!uniqueHistory.has(item.id)) {
                        uniqueHistory.set(item.id, {
                            id: item.id,
                            start_time: item.start_time,
                            scan_url: item.scan_url,
                            end_time: item.end_time,
                        });
                    }
                });
                const storedScanId = localStorage.getItem('scanId');
                const storedScanUrl = localStorage.getItem('scanUrl');
                const storedScanStartTime = localStorage.getItem('scanStartTime');
                const storedScanRunning = localStorage.getItem('scanRunning');
                const currentScanTimelineId = localStorage.getItem('currentScanTimelineId');
                this.timelines = Array.from(uniqueHistory.values())
                    .sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
                if (storedScanId && storedScanRunning === 'true') {
                    const ongoingTimeline = this.timelines.find(timeline => timeline.id === currentScanTimelineId);
                    if (ongoingTimeline) {
                        ongoingTimeline.isCurrent = true;
                    } else {
                        this.timelines.unshift({
                            id: storedScanId,
                            start_time: storedScanStartTime,
                            scan_url: storedScanUrl,
                            isCurrent: true
                        });
                    }
                    localStorage.setItem('scanTimeline', JSON.stringify(this.timelines));
                }
            } catch (error) {
                console.error(error);
            }
        },
        
        updateTimeTaken(scanId) {
        const selectedItem = this.timelines.find(item => item.id === scanId);
        if (selectedItem && selectedItem.start_time && selectedItem.end_time) {
            const startTime = new Date(selectedItem.start_time);
            const endTime = new Date(selectedItem.end_time);
            if (!isNaN(startTime.getTime()) && !isNaN(endTime.getTime()) && endTime > startTime) {
                const timeDiff = new Date(endTime - startTime);
                const hours = String(timeDiff.getUTCHours()).padStart(2, '0');
                const minutes = String(timeDiff.getUTCMinutes()).padStart(2, '0');
                const seconds = String(timeDiff.getUTCSeconds()).padStart(2, '0');
                this.timeTaken = `${hours}:${minutes}:${seconds}`;
            } else {
                this.timeTaken = "00:00:00";
                }
            }
        },

        async showScanResult(id) {
            if (!this.scanCompleted) {
                clearInterval(this.timeInterval);
            }
            this.selectedHistoryItem = null;
            this.selectedTimelineId = null;
            this.resultObj = [];
            this.findings = 0;
            this.vulnLevel.forEach(y => y.value = 0);
            this.selectedHistoryItem = null;
            this.scanResult = false;
            this.firstPage = false;
            this.timeTaken = null;
            this.selectedHistoryItem = id;
            const selectedItem = this.timelines.find(item => item.id === id);
            this.updateHistory()
            if (selectedItem) {
                this.scanUrl = selectedItem.scan_url;
                await this.scanAlert(id);
                if (selectedItem.start_time && selectedItem.end_time) {
                    const startTime = new Date(selectedItem.start_time);
                    const endTime = new Date(selectedItem.end_time);
                    if (!isNaN(startTime.getTime()) && !isNaN(endTime.getTime()) && endTime > startTime) {
                        const timeDiff = new Date(endTime - startTime);
                        const hours = String(timeDiff.getUTCHours()).padStart(2, '0');
                        const minutes = String(timeDiff.getUTCMinutes()).padStart(2, '0');
                        const seconds = String(timeDiff.getUTCSeconds()).padStart(2, '0');
                        this.timeTaken = `${hours}:${minutes}:${seconds}`;
                    } else {
                        this.timeTaken = "00:00:00";
                    }
                } else {
                    this.timeTaken = "Time data not available";
                }

                this.scanResult = true;
            }
        },

        async scanStop() {
            if (this.scanId) {
                try {
                    clearInterval(this.statusInterval);
                    clearInterval(this.scanInterval);
                    const response = await axios.post(`${this.$apiUrl}/webscan/stop`, { scan_id: this.scanId }, { withCredentials: true });
                    if (response.data.result) {
                        this.value = 0;
                        this.scanDisabled = false;
                        this.selectedHistoryItem = null;
                    } else {
                        console.error('Failed to stop scan');
                    }
                } catch (error) {
                    console.error("Stop scan error:", error);
                }
            } else {
                console.warn("No scan ID available to stop.");
            }
        },
        formatTimeline: function (start_time) {
            const date = new Date(start_time);
            return `${date.toDateString()} ${date.toLocaleTimeString()}`;
        },
        autoSelectLatestScan() {
            if (!this.selectedHistoryItem && this.timelines.length > 0) {
                this.selectedTimelineId = null;
                const latestScan = this.timelines[0];
                latestScan.isCurrent = false;
                this.timeTaken = null;
                this.showScanResult(latestScan.id);
                this.stopStopWatch();
                this.selectedTimelineId = latestScan.id;
            }
            this.updateHistory();
            this.stopStopWatch(); 
        },
        isValidUrl: function(string) {
            try {
                let url = new URL(string);
                if (url.protocol !== "http:" && url.protocol !== "https:") {
                    return false;
                }
                if (!url.hostname || url.hostname.indexOf('.') === -1) {
                    return false;
                }
                const regex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(:\d+)?(\/[^\s]*)?$/;
                if (!regex.test(string)) {
                    return false;
                }
                return true;
            } catch (_) {
                return false;
            }
        },
        handleBeforeUnload(event) {
            if (localStorage.getItem('scanRunning') === 'true') {
                const message = 'A scan is currently in progress. Are you sure you want to leave? Changes may not be saved.'
                event.preventDefault();
                event.returnValue = message;
                return message;
            }
        }
    },
    
    beforeRouteLeave(to, from, next) {
        this.clearIntervals();
        this.resultObj = [];
        // this.vulnLevel.forEach(y => y.value = 0);
        this.scanCompleted = false;
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
        next();
    },
    mounted() {
        this.updateHistory();
        const storedScanId = localStorage.getItem('scanId');
        const storedScanUrl = localStorage.getItem('scanUrl');
        const storedScanRunning = localStorage.getItem('scanRunning');
        const storedScanStartTime = localStorage.getItem('scanStartTime');
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        if (storedScanId && storedScanRunning === 'true' && !this.isProcessingResults) {
            const startTime = new Date(storedScanStartTime);
            const now = new Date();
            const elapsedSeconds = Math.floor((now - startTime) / 1000);
            this.min = Math.floor(elapsedSeconds / 60);
            this.sec = elapsedSeconds % 60;
            this.startStopWatch();

            this.isProcessingResults = true;
            this.scanId = storedScanId;
            this.scanUrl = storedScanUrl;
            this.scanDisabled = true;
            this.firstPage = false;
            this.scanResult = false;
            clearInterval(this.scanInterval);
            clearInterval(this.statusInterval);
            this.scanInterval = setInterval(async () => {
                const status = await this.scanStatus(this.scanId);
                if (status === '100') {
                    clearInterval(this.scanInterval);
                    this.scanCompleted = true;
                    this.resultObj = [];
                    await this.scanAlert(this.scanId);
                    await this.updateHistory();
                    this.autoSelectLatestScan()
                    this.scanResult = true;
                    this.stopStopWatch();
                    localStorage.removeItem('scanId');
                    localStorage.removeItem('scanUrl');
                    localStorage.removeItem('scanStartTime');
                    localStorage.removeItem('scanRunning');
                    this.isProcessingResults = false;
                }
            }, 3000);
        } else {
            clearInterval(this.scanInterval);
            clearInterval(this.statusInterval);
        }
    },
    computed:{
        curStatus: {
            get() {
                return this.$store.state.curStatus;
            },
            set(value) {
                this.$store.commit('setScanStatus', value);
            }
        },
        curResult(){
            return this.$store.state.curResult;
        }
    },
    watch:{
        curResult:{
            handler(newVal){
                this.resultObj=newVal;
            },
            immediate:true
        },
        resultObj:{
            handler(newVal){
                console.log(newVal);
            },
            immediate:true
        },
    }
}
</script>

<style>
.loginInput{
    height: 70vh;
}
.URLInput{
    height: 80vh;
}
.v-input__slot {
    min-height: 30px !important;
}
.v-text-field__slot input {
    font-size: medium;
}
.v-input__append-inner {
    margin-top: 10px !important;
}
.v-input {
    max-width: none;
}
.tdWidth {
    min-width: fit-content;
    width: 200px !important;
}
.v-expansion-panel-header__icon {
    display: none;
}
.fontBlack {
    color: black;
}
.darkGreen {
    filter: invert(43%) sepia(74%) saturate(436%) hue-rotate(73deg) brightness(91%) contrast(83%);
}
.v-data-table__wrapper {
    height: 100%;
}
.v-list::-webkit-scrollbar {
    display: none;
}
.noScroll::-webkit-scrollbar {
    display: none;
}
.v-input__append-inner {
    margin-top: 5px !important;
}
</style>