import Vue from "vue";
import VueRouter from "vue-router";
import AboutMe from "@/views/introduction.vue";
import LoginPage from "@/views/login.vue";
import ScanPage from "@/views/scan.vue";
import ScanHistory from "@/views/history.vue";
import Signup from "@/views/signup.vue";
import Settings from "@/views/settings.vue";
import store from "@/store/store";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'AboutMe',
        component: AboutMe
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/scan',
        name: 'ScanPage',
        component: ScanPage,
        meta: { requiresAuth: true },
    },
    {
        path: '/history',
        name: 'ScanHistory',
        component: ScanHistory,
        meta: { requiresAuth: true },
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: Signup
    },
    {
        path: '/settings',
        name: 'SettingPage',
        component: Settings,
        meta: {requiresAuth: true}
    }
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.state.isLogged) {
            next({
                path: '/login',
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else {
        next();
    }
});


export default router;